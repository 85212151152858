<template>
    <fieldset class="p-2 mt-3 border">
        <legend class="w-auto">Stücklisten</legend>
        <div class="row">
            <div class="col-md-6">
                <div class="card card-outline card-success" style="height: 55vh">
                    <div class="card-header">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadAvailableArticles" v-model="search" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadAvailableArticles">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body overflow-auto">
                        <table class="table table-striped">
                            <thead>
                                    <tr>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Artikelname</th>
                                        <th>Hersteller</th>
                                        <th>EK</th>
                                        <th>VK</th>
                                        <th style="width:15%; text-align:center;">Aktionen</th>
                                    </tr>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                        <th>
                                            <select class="form-control form-control-sm" v-model="params.producer_id">
                                                <option value=''>--Filter--</option>
                                                <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{  producer.number  }} - {{  producer.name  }}</option>
                                            </select> 
                                        </th>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(article,index) in availableArticles.data" :key="article.id">
                                    <td>{{ article.ordernumber }}</td>
                                    <td>{{ generateName(index) }}</td>
                                    <td>{{ article.article.producer.name }}</td>
                                    <td>{{ article.purchase_price | toCurrency}}</td>
                                    <td>{{ article.price | toCurrency}}</td>
                                    <td style="text-align:center;">
                                        <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                                        <b-button size="sm" @click="addArticle(index)" variant="primary"><i class="fas fa-plus"></i>Hinzufügen</b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <pagination class="float-left" :data="availableArticles" @pagination-change-page="loadAvailableArticles" :limit="3" v-if="notEmptyObject(availableArticles)"></pagination>
                    
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Artikel</option>
                            <option value="50">50 Artikel</option>
                            <option value="75">75 Artikel</option>
                            <option value="100">100 Artikel</option>
                            <option value="125">125 Artikel</option>
                            <option value="150">150 Artikel</option>
                        </select>
                        <p v-if="notEmptyObject(availableArticles)" class="float-right mr-2">Anzeige Eintrag {{availableArticles.meta.from }} - {{ availableArticles.meta.to }} von {{ availableArticles.meta.total }}</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-outline card-warning" style="height: 55vh">
                    <div class="card-header">
                        <h2 class="card-title">Zugewiesen Artikel</h2>
                        <div class="card-tools">
                            <ul class="nav">
                                <li class="nav-item">
                                    <div class="form-group row mr-4">
                                        <input type="checkbox" class="form-check-input mr-2" name="bundle_alternative" id="bundle_alternative" v-model="article.bundle_alternative" style="position: static; margin-left: 0;">
                                        <label for="bundle_alternative" class="control-label">Alternativartikel erlaubt</label>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <button class="btn btn-primary btn-xs" @click="saveBundles"><i class="fas fa-fw fa-save"></i> Speichern</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body overflow-auto">
                        <table style="width: 100%" class="table table-striped table-sm">
                            <thead>
                                <th style="width:30%">Name</th>
                                <th style="width:10%">Artikel-Nr.</th>
                                <th style="width:15%">Anzahl</th>
                                <th style="width:10%">EK</th>
                                <th style="width:10%">VK</th>
                                <th style="width:15%">VK-Gesamt</th>
                                <th style="width:10%">Aktion</th>
                            </thead>
                            <tbody>
                                <tr v-for="(data,index) in article.bundles" :key="'bundle' + data.id">
                                    <td>{{ data.name }}</td>
                                    <td>{{ data.ordernumber }}</td>
                                    <td><input type="number" class="form-control form-control-sm" v-model="article.bundles[index].qty" /></td>
                                    <td>{{ data.purchase_price | toCurrency }}</td>
                                    <td>{{ data.price | toCurrency }}</td>
                                    <td>{{ data.price * data.qty | toCurrency }}</td>
                                    <td>
                                        <button class="btn btn-danger btn-xs" @click="deleteArticle(index)"><i class="fas fa-fw fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-3 text-center">
                                <h6><strong>EK-Summe:</strong> {{ calculatePurchasePrice() | toCurrency}}</h6>
                            </div>
                            <div class="col-md-3 text-center">
                                <h6><strong>VK-Summe:</strong> {{ calculatePrice() | toCurrency}}</h6>
                            </div>
                            <div class="col-md-3 text-center">
                                <h6><strong>Stücklisten-Artikel-Preis:</strong> {{parseFloat(article.price) | toCurrency}}</h6>
                            </div>
                            <div class="col-md-3 text-center">
                                <h6 class="text-danger" ><strong>Rabatt:</strong>({{ ((100 / calculatePrice()) * ((parseFloat(article.price) - calculatePrice()) * -1)) / 100 | toPercent }}) {{ (parseFloat(article.price) - calculatePrice()) * -1 | toCurrency  }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    </fieldset>
</template>

<script>
export default {
    props: [
        'article',
        'producers',
        'taxes',
    ],

    data(){
        return{
            availableArticles: {},
            params: {
                sort_field: 'ordernumber',
                sort_direction: 'asc',
                producer_id: '',
                tax_id: '',
                per_page: 25,
                filter_bundles: 1,
                bundle_alternative: "0", 
            },
            search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.loadAvailableArticles();
            },
            deep: true
        },
    },

    methods:{

        calculatePurchasePrice(){
            var sum = 0;

            if(this.article.bundles.length > 0){
                this.article.bundles.forEach(function (item){
                    sum += (item.purchase_price * item.qty)
                });
            }

            return sum;

        },

        calculatePrice(){
            var sum = 0;

            if(this.article.bundles.length > 0){
                this.article.bundles.forEach(function (item){
                    sum += (item.price * item.qty)
                });
            }

            return sum;

        },

        saveBundles(){
            this.axios
                .post("/articles/" + this.$route.params.id + "/updateBundles", {articles: this.article.bundles, alternative: this.article.bundle_alternative})
                .then(() => {
                    // this.availableArticles = response.data;
                    //this.loadArticle();
                    this.$swal({
                        icon: "success",
                        title: "Stückliste gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    // this.$parent.loadArticle();
                    this.$emit('load_articles');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        deleteArticle(index){
            this.$delete(this.article.bundles, index);
        },

        addArticle(index){
            var article = this.availableArticles.data[index];

            //Prüfe ob der Artikel bereits im Warenkorb liegt

            const result = this.article.bundles.findIndex(item => item.ordernumber == article.ordernumber);

            if(result == -1)
            {
                var article_Data = {
                    'ordernumber': article.ordernumber,
                    'name': this.generateName(index),
                    'qty': 1,
                    'purchase_price': article.purchase_price,
                    'price': article.price,
                    'id': article.id,
                };

                this.article.bundles.push(article_Data);
            }

            else
            {
                this.article.bundles[result].qty = parseFloat(this.article.builds[result].qty) + 1;
            }
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        generateName(index)
        {
            var article = this.availableArticles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

        loadAvailableArticles(page = 1){
            this.availableArticles = [];
            //this.uploadImageForm.article_id = this.article.id;
            this.axios
                //.post("/articles/similiar", {article_id: this.$route.params.id, articles: this.articles})
                .get("/details", {
                    params: {
                        page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.availableArticles = response.data;
                    //this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    mounted() {
        this.loadAvailableArticles();
    }
}
</script>

<style>

</style>